<template>
  <!-- Cover Image-->
  <div
    class="w-100 d-lg-flex align-items-center justify-content-center px-5 mt-5"
  >
    <b-img
      fluid
      :src="pageData.coverImage"
      alt="Cover image"
    />
  </div>
</template>
<script>
import {
  BImg,
  VBTooltip,
} from 'bootstrap-vue'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BImg,
  },
  data() {
    return {
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      pageData: {},
    }
  },
  computed: {
  },
  created() {
    if (this.getPageData) {
      this.isLoaddingPreloader = false
      this.pageData = this.getPageData
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
