<template>
  <!-- Brand logo-->
  <div class="row justify-content-end margin-top-icon icon-margin-top-sm mb-5">
    <div
      v-for="media in pageData.mediaLinks"
      :key="media.id"
      class="col-2 col-md-1 col-lg-2 p-0"
    >
      <a
        :href="media.link"
        target="_blank"
      >
        <b-img
          fluid
          :src="media.icon"
          :alt="media.name || 'media image'"
          :style="{ maxWidth: '33px' }"
        />
      </a>
    </div>
  </div>
  <!-- /Brand logo-->
</template>
<script>
import {
  BImg,
  VBTooltip,
} from 'bootstrap-vue'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BImg,
  },
  data() {
    return {
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      pageData: {},
    }
  },
  computed: {
  },
  created() {
    if (this.getPageData) {
      this.pageData = this.getPageData
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
