<template>
  <!-- Brand logo-->
  <b-link
    to="/"
    class="brand-logo"
  >
    <div class="row">
      <div
        :class="
          pageData.slug.toLowerCase() === 'ois'
            ? 'col-md-3 col-sm-12'
            : 'col-md-2 col-sm-12'
        "
      >
        <b-img
          fluid
          :src="pageData.logo || pageData.femsLogo"
          alt="Fems"
          :style="{ height: '50px' }"
        />
      </div>
      <div
        :class="
          (pageData.slug.toLowerCase() === 'ois' ? 'col-md-9' : 'col-md-10') +
            ' col-sm-12'
        "
      >
        <h2
          class="font-weight-bold font-size-sm font-size-md mb-1"
          :style="{
            lineHeight: pageData.slug.toLowerCase() === 'ois' ? '50px' : '',
          }"
        >
          {{ pageData.name || "Fingerprint Education Management System" }}
        </h2>
      </div>
    </div>
  </b-link>
  <!-- /Brand logo-->
</template>
<script>
import { BLink, BImg, VBTooltip } from 'bootstrap-vue'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BLink,
    BImg,
  },
  data() {
    return {
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      userName: LocalStorageService.get('user_username'),

      pageData: {},
    }
  },
  computed: {},
  created() {
    // console.log(this.getPageData)
    if (this.getPageData) {
      this.isLoaddingPreloader = false
      this.pageData = this.getPageData
    }
  },
  methods: {},
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
