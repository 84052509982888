<template>
  <div
    class="row mb-0 footer-text-sm"
    :style="{ fontSize: '12px' }"
  >
    <b-col
      lg="6"
      md="6"
      sm="12"
      class="font-weight-bold"
    >
      <p class="mb-0 ">
        Pay with  <b-img
          fluid
          :src="pageData.moneybagLogo"
          alt="fitl"
          :style="{ height: '20px' }"
        /><b :style="{ marginLeft: '25px' }" /> Powered By
        <b>
          <b-img
            fluid
            :src="pageData.poweredByLogo"
            alt="fitl"
            :style="{ height: '16px'}"
          /></b>
      </p>
    </b-col>
    <b-col
      lg="6"
      md="6"
      sm="12"
      class="font-weight-bold"
    >
      <p class="mb-0 text-right  footer-text-sm">
        <a
          :href="pageData.knowladeBasedLink"
          target="_black"
        >
          Knowledge Base </a>| <a
          :href=" pageData.videoTutorialLink"
          target="_black"
        >
          Video Tutorial
        </a> |
        <a
          :href="pageData.faqLink"
          target="_black"
        >
          Frequently Asked Question
        </a> |
        <a
          :href="pageData.helpDeskLink"
          target="_black"
        >
          Helpdesk
        </a></p>
      <p class="mb-0 text-right footer-text-sm">
        Email: {{ pageData.email }} | Hotline {{ pageData.mobile_no }}
      </p>
    </b-col>
  </div>
</template>
<script>
import {
  BCol,
  BImg,
  VBTooltip,
} from 'bootstrap-vue'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BImg,
    BCol,
  },
  data() {
    return {
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      pageData: {},
    }
  },
  computed: {
  },
  created() {
    if (this.getPageData) {
      this.pageData = this.getPageData
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
